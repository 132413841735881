<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5137 1.14336L22.6056 20.2352C23.1914 20.821 23.1914 21.7708 22.6056 22.3566C22.0198 22.9423 21.07 22.9423 20.4843 22.3566L1.39238 3.26468C0.80659 2.67889 0.806589 1.72914 1.39238 1.14336C1.97816 0.557572 2.92791 0.557571 3.5137 1.14336Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6076 3.26467L3.51574 22.3566C2.92995 22.9423 1.98021 22.9423 1.39442 22.3566C0.808633 21.7708 0.808633 20.821 1.39442 20.2352L20.4863 1.14335C21.0721 0.557566 22.0218 0.557566 22.6076 1.14335C23.1934 1.72914 23.1934 2.67889 22.6076 3.26467Z"
      fill="black"
    />
  </svg>
</template>
